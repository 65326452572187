import React, { useState } from 'react';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import Input from './Input';
import MailSent from './MailSent';
import LoadingButton from './LoadingButton';
import axios from 'axios';
import validateContact from '../validation/validateContact';
import MailError from './MailError';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

const initialState = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const Contact = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const messageLength = state.message.length;

  const handleSubmit = async () => {
    const errors = validateContact(state);
    if (errors) return setErrors(errors);
    setErrors({});

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API}/contact/`, state);
      setLoading(false);
      setState(initialState);
      setMailSent(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrors(error.response.data);
      setMailError(true);
    }
  };

  return (
    <div>
      <h2>Contactformulier</h2>
      <MailSent open={mailSent} setMailSent={setMailSent} />
      <MailError open={mailError} setMailError={setMailError} />
      <form>
        <Input
          error={errors.name}
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
          label='Uw naam'
        />
        <Input
          error={errors.email}
          value={state.email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          label='Uw e-mailadres'
        />
        <Input
          error={errors.subject}
          value={state.subject}
          onChange={(e) => setState({ ...state, subject: e.target.value })}
          label='Onderwerp'
        />
        <Input
          error={errors.message}
          value={state.message}
          onChange={(e) => setState({ ...state, message: e.target.value })}
          label='Uw bericht'
          multiline
          rows={12}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!errors.message && (
            <Typography
              variant='caption'
              style={{
                color:
                  messageLength < 2000 ? theme.palette.primary.main : 'red',
              }}
            >
              {messageLength}/2000
            </Typography>
          )}
        </div>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          className={classes.button}
          variant='contained'
          color='primary'
        >
          Verzend
        </LoadingButton>
      </form>

      <h2>Telefonisch contact</h2>

      <p>
        Om direct een afspraak te maken kunt u bellen naar het CIG (
        <a href='tel:+31206381279'>020-6381279</a>). Bereikbaar van maandag t/m
        vrijdag tussen 9:00 en 17:00.
      </p>
    </div>
  );
};

export default Contact;
