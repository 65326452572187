import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 10,
    marginBottom: 10,
    boxSizing: "border-box",
    margin: 0,
    background: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    textAlign: "center",
  },
  iconButton: {
    float: "right",
  },
}));

// Error when mail is not sent
const MailError = ({ open, setMailError }) => {
  const classes = useStyles();
  return (
    open && (
      <div className={classes.root}>
        Er ging iets mis! Mail niet verzonden!
        <IconButton
          onClick={() => setMailError(false)}
          className={classes.iconButton}
          size="small"
          color="primary"
        >
          <HighlightOff />
        </IconButton>
      </div>
    )
  );
};

export default MailError;
