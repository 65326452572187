import React from 'react';
import zhong from '../images/pictures/logo-zhong.jpg';
import kab from '../images/pictures/logo-kab.jpg';
import scag from '../images/pictures/logo-scag.jpg';

const Info = () => {
  return (
    <section>
      <h2>Tarieven acupunctuurpraktijk Quan Yin</h2>
      <table id='pricelist'>
        <thead>
          <tr>
            <th>Omschrijving</th>
            <th>Prijs</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Consult</td>
            <td>€85,-</td>
          </tr>
          <tr>
            <td>Consult studenten</td>
            <td>€60</td>
          </tr>
          <tr>
            <td>Consult tot 18 jaar</td>
            <td>€50,-</td>
          </tr>
        </tbody>
      </table>

      <p>
        Afspraken kunnen zonder probleem tot 24u van tevoren verzet of afgezegd
        worden. Indien u onverwacht niet op komt dagen zonder geldige reden zal
        het consult in rekening gebracht worden.
      </p>

      <p>
        Ik ben lid van de beroepsvereniging{' '}
        <a href='http://zhong.nl/' target='__blank'>
          ZHONG
        </a>{' '}
        dus de meeste zorgverzekeraars vergoeden de acupunctuurconsulten of een
        deel daarvan mits u een aanvullend pakket heeft. Een verwijsbriefje van
        de huisarts is meestal niet nodig.
      </p>

      <p>
        U kunt op <a href='https://www.zorgwijzer.nl/'>zorgwijzer</a> bekijken
        of uw verzekeraar mijn consult vergoedt.
      </p>

      <h2>Over acupunctuurpraktijk Quan Yin</h2>

      <div className='well'>
        Quan Yin: godin van mededogen, krijgsvrouw, godin met de duizend
        handjes.
      </div>

      <h2>De praktijk</h2>

      <p>
        Acupunctuurpraktijk Quan Yin is op dit moment gehuisvest in{' '}
        <a href='http://www.cig-amsterdam.nl' target='__blank'>
          Centrum Integrale Geneeskunde
        </a>{' '}
        aan de Sarphatikade 22. In deze praktijk zijn meerdere disciplines
        verenigd waardoor uitwisseling van kennis makkelijk en gebruikelijk is.
      </p>

      <p>
        De praktijk is toegankelijk met een rolstoel mits u van tevoren even
        belt. (<a href='tel:+31206381279'>020-6381279</a>, maandag t/m vrijdag
        tussen 9:00 en 17:00).
      </p>

      <p>
        Voor mijn privacy beleid, zie het{' '}
        <a href='https://www.cig-amsterdam.nl/privacybeleid/' target='__blank'>
          privacybeleid van het CIG.
        </a>
      </p>

      <p>KVK-nummer: 34306765</p>

      <div className='logo-banner'>
        <a href='https://zhong.nl/' target='__blank'>
          <img src={zhong} alt='logo zhong' />
        </a>

        <a href='https://kab-koepel.nl/' target='__blank'>
          <img src={kab} alt='logo kab' />
        </a>
        <a href='https://www.scag.nl/' target='__blank'>
          <img src={scag} alt='logo scag' />
        </a>
      </div>
    </section>
  );
};

export default Info;
